import * as React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"

const Container = styled.div`
  max-width: 80ch;
  margin: 80px auto 0px;
  text-align: center;
  padding: 1em;

  h2 {
    color: var(--primary);
    font-size: var(--h2);
  }

  .link-container {
    color: var(--primary);
    font-weight: bold;
  }

  span {
    color: var(--primary);
  }
`

const about = () => {
  return (
    <>
      <Seo title="Kilka słów o nas" />
      <Layout>
        <SimpleBanner title="Kilka słów o nas">
          <StaticImage
            className="banner__image desktop"
            src="../images/about.jpg"
          />
          <StaticImage
            className="banner__image mobile"
            src="../images/about-mobile.jpg"
          />
        </SimpleBanner>
        <Container>
          <h2>Nasza praca to przyjaźń i pasja</h2>
          <p>
            Zespół Memento został założony w 2009 roku na terenie województwa
            Śląskiego, a dokładniej w okolicach Katowic. Zespół powstał przede
            wszystkim z ogromnej pasji do muzyki, a z czasem pojawiła się chęć
            stworzenia Grupy, która z zaangażowaniem i profesjonalizmem wykonywałaby
            swoją pracę.
          </p>
          <span>○</span>
          <p>
            Posiadamy ponad 10 letnie doświadczenie w prowadzeniu i oprawie
            muzycznej imprez, uroczystości weselnych, bankietów, eventów itd.,
            gdzie oprawa muzyczna stanowi niemal 100% naszej pracy. Swoją
            działalność prowadzimy głównie na terenie Polski, jednak nie jest
            dla nas przeszkodą przekroczyć granice naszego kraju - grywaliśmy
            już niejednokrotnie na terenie Wysp Brytyjskich, a bariera językowa
            nie stanowi dla nas problemu.
          </p>
          <span>○○</span>
          <p>
            W naszym repertuarze znaleźć można różnorodną muzykę, począwszy od
            największych przebojów polskiej i zagranicznej muzyki rozrywkowej,
            poprzez hity lat 70” 80” 90”, kończąc na muzyce współczesnej. Na
            przestrzeni ponad 10 letniego doświadczenia zagraliśmy setki udanych
            imprez, dzięki czemu mogliśmy wypracować nasz jedyny w swoim rodzaju
            styl i klimat prowadzonych imprez. Każdą uroczystość cechuje inny i
            niepowtarzalny charakter, a nasze doświadczenie w dobrze repertuaru
            gwarantuje zabawę na najwyższym poziomie.
          </p>
          <span>○○○</span>
          <p>
            Rzeczywistą miarą jakości naszych usług jest rzesza zadowolonych
            klientów oraz realne opinie dostępne w sieci, które z każdym
            kolejnym rokiem się powiększają.
          </p>
          <span>♡ Memento Group ♡</span>
        </Container>
      </Layout>
    </>
  )
}

export default about
